<template>
    <div class="checkout-cb-view">
        <bxs-top-nav hide-undo>{{ $t('Prenotazione completata') }}</bxs-top-nav>

        <bxs-data-lazy
        v-model="data_page"
        :call="() => $leila.me.getOrder($route.params.id)"
        class="mt-ui">
            <section>
                <bxs-layout>
                    <div class="mb-6">
                        <h4 class="mb-2">{{ $t('Grazie per la tua prenotazione!') }}</h4>
                        <p>{{ $t('Riceverai una mail di conferma con le istruzioni, controlla nella cartella spam se non ricevi nessuna comunicazione.') }}</p>
                    </div>

                    <!--  -->
                    <div class="mb-6">
                        <bxs-title class="mb-ui">{{ $t('Oggetto') }}</bxs-title>

                        <bxs-card class="pa-ui">
                            <product-row
                            :item="data_page.product"
                            hide-actions
                            style="pointer-events: none;" />
                        </bxs-card>
                    </div>

                    <!-- ritiro -->
                    <div
                    v-if="data_page.shipping.method !== 'delivery'"
                    class="mb-6">
                        <div class="mb-ui">
                            <bxs-title>{{ $t('Ritiro oggetto') }}</bxs-title>
                            <p>{{ $t('Vieni a ritirare il tuo oggetto') }} <b>{{ $t('a partire dal') }} {{ $filters.toHumanDate(data_page.loan.from_date, 'L') }}</b></p>
                        </div>

                        <bxs-card class="pa-ui">
                            <div class="flex align-center">
                                <bxs-btn width="100%">{{ $filters.toHumanDate(data_page.loan.from_date) }}</bxs-btn>

                                <bxs-spacer />

                                <bxs-icon
                                width="60"
                                name="arrow-right"
                                class="mx-12"></bxs-icon>

                                <bxs-spacer />

                                <bxs-btn width="100%">{{ $filters.toHumanDate(data_page.loan.to_date) }}</bxs-btn>
                            </div>
                        </bxs-card>
                    </div>

                    <!-- delivery -->
                    <div
                    v-else
                    class="mb-6">
                        <bxs-alert class="mb-ui">{{ $t('Il periodo di prestito verrà calcolato e concordato in base alla data della consegna.') }}</bxs-alert>
                    </div>

                    <!--  -->
                    <div
                    v-if="!!data_page.loan.pickup_Code"
                    class="mb-6">
                        <bxs-title class="mb-ui">{{ $t('Istruzioni smartlocker') }}</bxs-title>
                        <bxs-card class="pa-ui">
                            <div
                            v-if="data_page.shipping.address.smartlocker_instruction"
                            v-html="data_page.shipping.address.smartlocker_instruction"
                            class="mb-ui"></div>

                            <div>
                                <div class="flex nowrap align-center justify-between mb-ui">
                                    <p class="mb-1"><b>{{ $t('Codice ritiro') }}</b>: {{ data_page.loan.pickup_Code }}</p>
                                    <img
                                    :src="$filters.toStaticFile(data_page.loan.pickup_qr_code)"
                                    alt=""
                                    style="max-width: 80px;">
                                </div>

                                <div class="flex nowrap align-center justify-between">
                                    <p class="mb-1"><b>{{ $t('Codice deposito') }}</b>: {{ data_page.loan.deposit_code }}</p>
                                    <img
                                    :src="$filters.toStaticFile(data_page.loan.deposit_qr_code)"
                                    alt=""
                                    style="max-width: 80px;">
                                </div>
                            </div>
                        </bxs-card>
                    </div>

                    <!-- end -->
                    <div class="mb-12">
                        <bxs-card class="pa-ui">
                            <headquarter-row
                            :item="data_page.shipping.address"
                            class="my-2" />
                        </bxs-card>
                    </div>

                    <!-- foot -->
                    <div>
                        <div class="mb-ui">
                            <!-- <bxs-title>Restituzione oggetto</bxs-title> -->
                            <p v-if="data_page.shipping.method !== 'delivery'">{{ $t('L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse') }} <router-link to="/help">{{ $t('contattaci') }}</router-link>.</p>
                            <p v-else>{{ $t('L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,') }} <router-link to="/help">{{ $t('contattaci') }}</router-link>.</p>
                        </div>

                        <p
                        v-if="data_page.shipping.method !== 'delivery'"
                        class="mb-ui">{{ $t('Se hai bisogno del trasporto o di ritirarlo con più urgenza') }} <router-link to="/help">{{ $t('contattaci') }}</router-link> {{ $t('che troviamo una soluzione.') }}</p>
                        <p>{{ $t('Ovviamente non c\'è bisogno di attendere la scadenza del prestito per restituire l\'oggetto, gli altri soci non potranno che esserne felici.') }}</p>
                    </div>
                </bxs-layout>
            </section>

            <div class="bxs-divider"></div>

            <section>
                <bxs-layout>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="arrow-right"
                        spacer
                        to="/help">{{ $t('Supporto') }}</bxs-list-item>

                        <bxs-list-item
                        append-icon="arrow-right"
                        spacer
                        to="/me">{{ $t('Il mio profilo') }}</bxs-list-item>

                        <bxs-list-item
                        append-icon="arrow-right"
                        spacer
                        to="/">{{ $t('Riparti') }}</bxs-list-item>
                    </bxs-list>
                </bxs-layout>
            </section>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'checkout_cb',
    data () {
        return {
            data_page: null
        }
    },
    methods: {
        toPaypal () {
            window.open('https://www.paypal.com/paypalme/leilabologna/5', '_blank')
        }
    }
}
</script>